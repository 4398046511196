.form-add-apartamento {
  display: flex;
  flex-direction: column;
}
.andar-tipo-apar-form {
  display: flex;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  transition: 0.2s ease;
}
.custom-file-upload:hover {
  background-color: rgb(245, 245, 245);
  transition: 0.2s ease;
}

@media (max-width: 800px) {
  .andar-tipo-apar-form {
    flex-wrap: wrap;
  }
  .wpApartamentoId {
    width: 100% !important;
  }
  .unidadesId-form {
    margin: 0 !important;
  }
  .apartamento-form {
    margin: 0 !important;
  }
  .andar-form {
    margin: 0 !important;
  }
}
