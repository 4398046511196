.ass-categoria-quantidade {
  min-width: 20% !important;
  /* background-color: pink; */
}

/* Modal Produtos */

.add-prod-form {
  display: flex;
  width: 100%;
}
.add-prod-selector {
  width: 80%;
  margin-right: 20px;
}

.add-prod-btn {
  min-width: 5%;

  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-list {
  display: flex;
  width: 100%;
}

.menu-list-cats {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 100%;
}

.menu-prods {
  /* height: 50px; */
  min-height: 4vh;
  width: 90%;
  display: flex;
  align-items: center;
}

.menu-prods-extra {
  display: flex;
  align-items: center;
  /* width: 5%; */
  justify-content: center;
  margin-right: 20px;
}

.menu-prods-remover {
  display: flex;
  align-items: center;
  /* width: 5%; */
  justify-content: center;
  /* margin-right: 20px; */
  color: #f44336;
}

/* Modal Categorias */
.menu-cats {
  /* height: 50px; */
  min-height: 4vh;
  width: 58%;
  display: flex;
  align-items: center;
}

.menu-cats-remover {
  display: flex;
  align-items: center;
  /* width: 5%; */
  justify-content: center;
  margin-right: 20px;
  margin-left: 20px;
  color: #f44336;
}
