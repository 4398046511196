/* Reservas */
.listaReservas {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* align-items: flex-start; */
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 50px;
}

/* Reserva */

.reservaApartamentoCard {
  width: 225px;
  /* height: 250px; */
  background-color: #f3f3f358;
  border-radius: 5px;
  margin: 0px 10px;
  margin-bottom: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);

  cursor: pointer;
}

.reservaDescricao {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 0px;
  margin: 0px 5px 0px 10px;
}

.infoReservaCard {
  /* background: lightpink; */
  width: 38%;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
  min-height: 34vh;
  max-height: 400px;
}

.detalhesReservaCard {
  /* background: lightgreen; */
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
}

.back-btn {
  width: 40px !important;
  height: 45px;
  /* margin-top: 30px !important; */
}

.infoReservaP {
  margin-top: 30px;
  margin-left: 20px;
}

.infoReservaP > p {
  margin-bottom: 10px;
}

.detalhesReservaDrops {
  margin-top: 40px;
}

.topReserva {
  display: flex;
}

.reservaTitulo {
  width: 80%;
  /* text-align: center; */
  margin-left: 5%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: #ccc 1px solid;
}

.reservaCards {
  display: flex;
  justify-content: space-around;
}

.float-edit-reserva {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

@media (max-width: 800px) {
  /* Reservas */
  .listaReservas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .searchContainer {
    display: flex;
    flex-direction: column;
  }

  /* Reserva */
  .reservaApartamentoCard {
    margin-bottom: 20px;
  }

  .reservaCards {
    flex-direction: column;
    align-items: center;
  }

  .infoReservaCard {
    width: 100%;
  }
  .detalhesReservaCard {
    width: 100%;
    margin-bottom: 40px;
  }
  .float-edit-reserva {
    width: 80%;
  }
}
