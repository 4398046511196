.header-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.titulo-modal {
  text-align: center;
  margin-bottom: 20px;
}

.descricao-modal {
  width: 100%;
}

.table-btns {
  display: flex;
  justify-content: flex-end;
}
