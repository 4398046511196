*,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'poppins', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.clickable {
  cursor: pointer;
}
.spinner-position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tituloPagina {
  font-size: 20px;
  font-weight: 500;
}

.errorMessage {
  color: red;
  font-size: small;
  margin-top: 10px;
}

.width-100 {
  width: 100% !important;
}

@media (max-width: 800px) {
  .m-r-zero-mobile {
    margin: 0 !important;
  }
}

/* ::-webkit-scrollbar {
  width: 0.3rem;
}
::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
} */

/* ::-webkit-scrollbar {
  width: 0.3rem;
}
::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
} */

::-webkit-scrollbar {
  width: 1.3em;
  height: 0;
}
::-webkit-scrollbar-thumb {
  height: 1em;
  border: 0.5em solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
